import { getSrc } from 'gatsby-plugin-image';
import { ContentfulCertification } from '../generated/gatsby.types';

const transformCertification = (certifications: Array<ContentfulCertification> | null | undefined): any => {
  if (!certifications) {
    return [];
  }

  return certifications.map((certification: ContentfulCertification) => {
    return {
      title: certification.title,
      imageUrl: getSrc(certification.asset?.gatsbyImageData),
      to: certification.url ? certification.url : null,
    };
  });
};

export default transformCertification;
